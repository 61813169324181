import React from "react"
import styled, { keyframes } from "styled-components"
import Icon from "../../../components/shared/icon"
import { Link } from "gatsby"
import Pattern from "../../../components/shared/pattern"

const fadeIn = keyframes`
  0% {
    opacity: 0; 
    transform: translate3d(0, 2rem, 0);
  }

  100% {
    transform: none; 
  }
`

const Box = styled.article`
  position: relative;
  padding: 4.5rem;
  padding-bottom: 7.5rem;
  animation: ${fadeIn} 1s cubic-bezier(0.22, 1, 0.36, 1) both;

  .xn-pattern {
    width: 20%;
    height: 18%;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
  }

  @media screen and (max-width: 766px) {
    padding: 3rem;
    padding-bottom: 5rem;
  }
`

const Photo = styled.figure`
  position: relative;
  height: 14rem;
  overflow: hidden;

  a {
    display: block;
    height: 100%;
    overflow: hidden;
    transition: opacity 0.5s;

    &:hover {
      opacity: 0.75;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  time {
    background-color: #fff;
    padding: 1rem;
    padding-left: 0;
    padding-top: 1.25rem;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;

    span {
      position: relative;
      padding-left: 0.4rem;
      top: -3px;
    }
    div {
      height: 0.75rem;
      width: 0.75rem;
      display: inline-block;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const Copy = styled.div`
  padding-top: 2.5rem;

  h2 {
    padding-bottom: 2rem;
    font-size: 1.65rem;
    color: ${props => props.theme.mint600};
    letter-spacing: -0.5px;
  }

  p {
    line-height: 1.6rem;
  }
`

const Article = ({ photo, title, slug, summary, date, className }) => {
  return (
    <Box className={className}>
      <Photo>
        <Link to={"/articles/" + slug}>
          <img src={photo} alt={title} />
        </Link>
        <time>
          <div>
            <Icon title="clock" />
          </div>{" "}
          <span>{date}</span>
        </time>
      </Photo>
      <Copy>
        <h2>
          <Link to={"/articles/" + slug}>{title}</Link>
        </h2>
        {summary && <p>{summary.substring(0, 80)}...</p>}
      </Copy>
      <Pattern type="dot" size="4" />
    </Box>
  )
}

export default Article
