import styled from "styled-components"

const ArticlesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;

  article {
    width: 33.33333%;

    &.hide {
      display: none;
    }
  }

  @media screen and (max-width: 766px) {
    display: block;

    article {
      width: 100%;
    }
  }
`

export default ArticlesGrid
