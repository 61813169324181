import React from "react"
import styled from "styled-components"
import Icon from "../../components/shared/icon"
import Photo from "../../images/corporate-ugandans.jpg"

const Box = styled.section`
  text-align: center;
  padding: 6rem 0;
  color: #fff;
  overflow: hidden;
  background-color: #027061;
  position: relative;

  h1 {
    font-size: 3rem;
    padding-bottom: 2.25rem;
    letter-spacing: -0.5px;
  }

  p {
    width: 60%;
    margin: 0 auto;
    line-height: 1.5rem;
  }
`

const Nav = styled.nav`
  padding-top: 2.5rem;
  div {
    width: 4rem;
    height: 4rem;
    border: solid 1px #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 1rem;
  }

  a {
    display: inline-flex;
    padding: 1.5rem 2rem;
    text-align: center;
    align-items: center;
    flex-direction: column;

    &:hover {
      div {
        background: ${props => props.theme.black};
        border-color: ${props => props.theme.black};
      }
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  span {
    padding-left: 0.45rem;
    font-weight: 600;
    font-size: 0.75rem;
    position: relative;
    text-transform: uppercase;
  }

  @media screen and (max-width: 766px) {
    a {
      display: flex;
      flex-direction: row;
      padding-top: 0.5rem;

      div {
        width: 3rem;
        height: 3rem;
        margin-bottom: 0;
      }

      span {
        padding-left: 1rem;
      }
    }
  }
`

const Wrap = styled.div`
  width: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 766px) {
    width: 100%;

    p {
      width: 90%;
    }
  }
`

const Bkg = styled.figure`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    filter: grayscale(100%);
  }
`

const Hero = () => {
  return (
    <Box>
      <Wrap>
        <h1>How can we help?</h1>
        <p>
          Explore questions customers ask and learn how XENO can help you plan,
          save and invest for your financial goals.
        </p>
        <Nav>
          <a href="#support-faqs">
            <div>
              <Icon fill="#ffcf41" title="question" />
            </div>
            <span>Common Questions</span>{" "}
          </a>

          <a href="#support-guide">
            <div>
              <Icon fill="#62f665" title="flag" />
            </div>
            <span>Getting Started</span>
          </a>

          <a href="#support-articles">
            <div>
              <Icon fill="#00cbff" title="document" />
            </div>
            <span>Investment Library</span>
          </a>
        </Nav>
      </Wrap>
      <Bkg>
        <img src={Photo} alt="" />
      </Bkg>
    </Box>
  )
}

export default Hero
