import React from "react"
import Button from "../../components/shared/button"
import Header from "./header"
import Action from "./section-action"
import Article from "../learn/articles/article"
import { useArticlesData } from "../learn/articles/data"
import Grid from "../../components/shared/articles-grid"

const Articles = () => {
  const data = useArticlesData()

  return (
    <section id="support-articles">
      <Header>
        <h1>
          Investment <span>Library</span>
        </h1>
        <p>
          Explore and learn about everything from investment basics to
          monitoring your financial goals using XENO.
        </p>
      </Header>

      <Grid>
        {data &&
          data.slice(0, 3).map((item, index) => {
            let node = item.node.data
            let firstPublished = node.published
            let lastPublished = node.first_publication_date
            return (
              <Article
                summary={node.summary.text}
                key={index}
                photo={node.cover.fluid.src}
                title={node.title.text}
                slug={item.node.uid}
                date={firstPublished ? firstPublished : lastPublished}
              />
            )
          })}
      </Grid>

      <Action>
        <Button label="View more guides" to="/" />
      </Action>
    </section>
  )
}

export default Articles
