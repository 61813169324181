import React, { useEffect } from "react"
import styled from "styled-components"
import PlusIcon from "../../images/plus.svg"
import MinusIcon from "../../images/minus.svg"
import DropIcon from "../../images/dropdown.svg"
import { useFaqsData } from "./data"
import Header from "../support/header"
import Icon from "../../components/shared/icon"

const Box = styled.ul`
  width: 50%;
  margin: 0 auto;
  padding: 4rem 0 12rem;

  button {
    font-size: 1.125rem;
    padding: 1.25rem 2.25rem 1.25rem 0;
    position: relative;
    display: flex;
    align-items: center;
    font-family: var(--xn-font-title);
    font-weight: bold;
    width: 100%;
    text-align: left;

    &:after {
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      background-image: url("${PlusIcon}");
      background-size: contain;
      background-repeat: no-repeat;
      right: 0;
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 1.5em);
      height: 100%;
      z-index: -1;
      background: ${props => props.theme.mint};
      left: -0.75rem;
      top: 0;
      border-radius: 4px;
      transition: opacity 0.5s;
      opacity: 0;
    }

    &:hover {
      opacity: 1;

      &:before {
        opacity: 0.2;
      }
    }

    &:active {
      color: inherit;
    }

    &.is-open {
      /* background: #ebf9f6;  */
      &:after {
        background-image: url("${MinusIcon}");
      }
    }
  }

  figure {
    height: 1.45rem;
    width: 1.45rem;
    margin-right: 1rem;

    &.investment {
      background: red;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  span {
    display: inline-block;
  }

  > li {
    &:first-child {
      > button {
        &:before {
          opacity: 0.2;
        }
      }
    }
  }

  .question {
    position: absolute;
    left: -2rem;
    top: 1.5rem;
    color: ${props => props.theme.mint600};
    font-size: 0.75rem;
  }

  li {
    border-bottom: solid 1px #f0f0f0;
    padding: 0.25rem 0;
    position: relative;

    &:hover {
      border-bottom-color: #fff;
    }
    &.hide {
      display: none;
    }
  }

  ul {
    padding: 0 0 3.5rem;
    width: 85%;
    margin: 0 auto;

    button {
      &:after {
        background-image: url("${DropIcon}") !important;
      }

      &.is-open {
        &:after {
          transform: rotateZ(180deg);
        }
      }
    }

    ul,
    ol {
      width: 100%;
      padding: 0;
      padding-bottom: 2.5rem;

      li {
        padding: 0;
        padding-bottom: 1rem;
        border-bottom: none;
      }
    }

    ul {
      list-style: disc inside;
    }

    ol {
      list-style: decimal inside;
    }
  }

  li,
  p {
    line-height: 1.75rem;
  }

  .panel-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @media screen and (max-width: 766px) {
    width: 80%;
  }
`
const Panel = styled.div`
  transition: height 0.45s ease;
  overflow: hidden;
  height: 0;

  &.is-visible {
    height: auto;
  }
`

const Faqs = () => {
  const groups = [
    "Popular Questions",
    "Investment",
    "Unit Trusts",
    "Contributions and Withdrawals",
    "My Account",
    "Fees",
    "Security",
  ]
  const faqs = useFaqsData()

  useEffect(() => {
    let categoryBtns = document.querySelectorAll(".category-btn")
    categoryBtns.forEach(btn => {
      btn.addEventListener("click", () => {
        btn.classList.toggle("is-open")
        btn.nextSibling.classList.toggle("is-visible")
      })
    })
  }, [])

  return (
    <>
      <Header className="lead">
        <h1>
          Common <span>Questions</span>
        </h1>
      </Header>
      <Box id="support-faqs">
        {groups &&
          groups.map((item, index) => {
            let questions = []
            faqs.forEach(ele => {
              if (ele.node.data.category === item) {
                questions.push(ele)
              }
            })

            return (
              <li
                key={index}
                className={questions.length > 0 ? "show" : "hide"}
              >
                <button
                  className={
                    index === 0 ? "category-btn is-open" : "category-btn"
                  }
                >
                  <figure>
                    <Icon title={item} fill="#009572" />
                  </figure>
                  <span>{item}</span>
                  <div></div>
                </button>

                <Panel className={index === 0 ? "is-visible" : ""}>
                  <div className="panel-content">
                    <ul>
                      {questions.length > 0 &&
                        questions.map((question, id) => (
                          <li key={id}>
                            {/* <span className="question">{id + 1}</span> */}
                            <button className="category-btn">
                              <span>{question.node.data.title.text}</span>
                            </button>
                            <Panel>
                              <div
                                className="panel-content"
                                dangerouslySetInnerHTML={{
                                  __html: question.node.data.description.html,
                                }}
                              ></div>
                            </Panel>
                          </li>
                        ))}
                    </ul>
                  </div>
                </Panel>
              </li>
            )
          })}
      </Box>
    </>
  )
}

export default Faqs
