import styled from "styled-components"

const Header = styled.section`
  text-align: center;
  width: 50%;
  padding-bottom: 6.5rem;
  margin: 0 auto;

  &.lead {
    padding-top: 6.5rem;
    padding-bottom: 0;
  }

  p {
    width: 60%;
    margin: 0 auto;
    line-height: 1.75rem;
  }

  h1 {
    font-size: 2.5rem;
    padding-bottom: 3rem;
    letter-spacing: -0.3px;
    text-transform: capitalize;
    span {
      color: ${props => props.theme.mint600};
    }
  }

  @media screen and (max-width: 766px) {
    width: calc(100% - 4rem);

    p {
      width: 100%;
    }
  }
`
export default Header