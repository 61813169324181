import React from "react"
import styled from "styled-components"
import Pattern from "../../components/shared/pattern"
import Button from "../../components/shared/button"
import Action from "./section-action"
import ProcessA from "../../images/process/xeno-process-01.png"
import ProcessB from "../../images/process/xeno-process-03.png"
import ProcessC from "../../images/process/xeno-process-02.png"
import Header from "./header"

const Steps = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 6.5rem;

  li {
    text-align: center;
    flex: 1;
    position: relative;
    border-right: solid 1px #beede6;

    &:last-child {
      border-right: none;
    }
  }

  span {
    background-color: ${props => props.theme.mint};
    color: ${props => props.theme.black};
    height: 3.5rem;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  figure {
    width: 11rem;
    height: 20rem;
    margin: 0 auto;
    margin-bottom: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.processA {
      background-image: url("${ProcessA}");
    }

    &.processB {
      background-image: url("${ProcessB}");
    }

    &.processC {
      background-image: url("${ProcessC}");
    }
  }

  h3 {
    font-family: var(--xn-font-title);
    margin: 2rem;
    font-size: 1.5rem;
    font-weight: bold;

    a:hover {
      color: ${props => props.theme.mint600};
    }
  }

  p {
    padding: 0 3.5rem;
    line-height: 1.6rem;
  }

  .xn-pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    padding-bottom: 50%;
    z-index: -1;
  }

  @media screen and (max-width: 766px) {
    display: block;

    p {
      width: 90%;
      margin: 0 auto;
    }
    li {
      padding-bottom: 4.5rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
`

const Guide = () => {
  return (
    <section id="support-guide">
      <Header>
        <h1>
          Getting <span>started</span>
        </h1>
        <p>
          Not sure where to start? Here's our basic three-step guide to help you
          setup a XENO account in under 5 minutes.
        </p>
      </Header>

      <Steps>
        <li>
          <span>1</span>
          <figure className="processA"></figure>
          <h3>
            <a href="https://myxeno.com/select-goal">Select Goal</a>
          </h3>
          <p>
            Choose from 8 goals like building wealth or setup an account for
            your Investment Club.
          </p>
          <Pattern size="4" type="dot" />
        </li>
        <li>
          <span>2</span>
          <figure className="processB"></figure>
          <h3>
            <a href="https://myxeno.com/select-goal">Setup Goal Targets</a>
          </h3>
          <p>
            Our intelligent guide will help you set targets and assess your risk
            tolerance.
          </p>
          <Pattern size="4" type="dot" />
        </li>
        <li>
          <span>3</span>
          <figure className="processC"></figure>
          <h3>
            <a href="https://myxeno.com/select-goal">Fund & Monitor</a>
          </h3>
          <p>
            Complete your profile and fund using Mobile Money or a Bank
            transfer.
          </p>
          <Pattern size="4" type="dot" />
        </li>
      </Steps>
      <Action>
        <Button label="Get Started" to="https://myxeno.com/select-goal" />
      </Action>
    </section>
  )
}

export default Guide
