import React from "react"
import Meta from "../utilities/seo"
import Hero from "../templates/support/hero"
import Faqs from "../templates/support/faqs"
import Guide from "../templates/support/guide"
import Articles from "../templates/support/articles"

const Support = () => {
  return (
    <>
      <Meta
        title="Support"
        image={{
          url:
            "https://res.cloudinary.com/xeno-technologies/image/upload/v1609843153/shutterstock_1838712622_recolor_wn2ppg.jpg",
          height: "2000",
          width: "1333",
        }}
        description="Explore common questions customers like you ask us, and learn how XENO can help you plan, save and invest for your financial goals."
      />
      <Hero />
      <Faqs />
      <Guide />
      <Articles />
    </>
  )
}

export default Support
